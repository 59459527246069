import './App.css';
import React, { useLayoutEffect } from 'react'
import Header from './components/Header';
import Services from './components/Services';
import TrainingCenter from './components/TrainingCenter';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import Login from './components/Login';
import Home from './components/Home';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {
  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/trainingcenters' element={<TrainingCenter />} />
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Home />} />
        </Routes>
        <div className="App">
          <Header />
        </div>
      </Wrapper>
    </Router>
  );
}

export default App;
