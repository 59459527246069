import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap'
import axios from 'axios';

const Login = () => {

    const [Email, setEmail] = useState();
    const [Password, setPassword] = useState();

    const handleSubmit = (e) => {

        e.preventDefault();

        let data = {
            username: Email,
            password: Password
        }

        axios.post("https://api.legendsqueue.com/api/training/auth/authenticate", data)
            .then(res => {

            })
            .catch(error => {

            })
    }

    return <div>
        <div className='row mt-5 pt-5'>
            <div className='col-sm-4'></div>
            <div className='col-sm-4'>
                <div className='card p-5'>
                    <Form>
                        <h1 className='pb-3'>Login</h1>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control value={Email} onChange={(e) => setEmail(e.target.value)} required type="email" placeholder="Enter email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control value={Password} onChange={(e) => setPassword(e.target.value)} required type="password" placeholder="Password" />
                        </Form.Group>
                        <Button variant="dark" type="submit">
                            Login
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    </div>;
};

export default Login;
