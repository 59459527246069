import React, { useEffect, useState } from 'react'
import { Table, Modal, Button, Form } from 'react-bootstrap'
import axios from 'axios'



const Services = () => {
    //Add and edit modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [Name, setService] = useState('');
    const [Duration, setDuration] = useState();
    //

    //Delete modal
    const [showDelete, setShowDel] = useState(false);

    const [Id, setServiceId] = useState(0);

    const handleCloseDel = () => setShowDel(false);
    //

    const [services, setServices] = useState([])

    useEffect(() => {
        axios.get("https://api.legendsqueue.com/api/training/services/getall")
            .then(res => {
                setServices(res.data);
            }).catch(error => {

            })
    }, [])

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = {
                Id: Id,
                Name: Name,
                Duration: Duration,
            };

            axios.post("https://api.legendsqueue.com/api/training/services/addorupdate", data)
                .then(res => {
                    handleClose();
                    if (Id !== 0) {
                        services.forEach(service => {
                            if (service.Id === Id) {
                                service.Name = data.Name;
                                service.Duration = data.Duration;
                            }
                        })
                    }
                    else {
                        data.Id = res.data;
                        setServices([...services, data]);
                    }

                    resetForm();

                }).catch(error => {

                })
        } catch (err) {

        }
    };

    function resetForm() {
        setService('')
        setDuration();
        setServiceId(0)
    }

    function getService(id) {
        try {
            axios.get(`https://api.legendsqueue.com/api/training/services/get/${id}`)
                .then(res => {
                    setService(res.data.Name)
                    setDuration(res.data.Duration)
                    setServiceId(res.data.Id)
                }).catch(error => {

                })
        } catch (err) {

        }
    };

    function showAddOrEditModal(id) {
        if (id.length !== 0) {
            getService(id)
        }
        setShow(true)
    }
    function showDeleteModal(id) {
        setServiceId(id)
        setShowDel(true)
    }

    let handleDelete = async (e) => {
        e.preventDefault();
        try {
            axios.post(`https://api.legendsqueue.com/api/training/services/delete/${Id}`)
                .then(res => {
                    handleCloseDel();
                    const updatedServices = services.filter((item) => item.Id != Id);
                    setServices(updatedServices);
                }).catch(error => {

                })
            setServiceId(0);
        } catch (err) {

        }
    };

    return (
        <div className='container mt-5 pt-5'>
            <h2 className='mb-3'>Services</h2>
            <Button className='mb-3' variant="dark" onClick={showAddOrEditModal}>
                Add service
            </Button>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Service</th>
                        <th>Duration</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {services.map((service, i) => (
                        <tr key={i}>
                            <td>{service.Id}</td>
                            <td>{service.Name}</td>
                            <td>{service.Duration}</td>
                            <td>
                                <a style={{ cursor: 'pointer' }} onClick={() => showDeleteModal(service.Id)}>Delete</a>&nbsp;&nbsp;<a style={{ cursor: 'pointer' }} onClick={() => showAddOrEditModal(service.Id)}>Edit</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Add and edit section */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Service</Form.Label>
                                    <Form.Control required value={Name} onChange={(e) => setService(e.target.value)} type="text" placeholder="Enter service name" />
                                </Form.Group>
                            </div>
                            <div className='col-sm-12'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Duration</Form.Label>
                                    <Form.Control required value={Duration} onChange={(e) => setDuration(e.target.value)} type="number" placeholder="Enter the duration of the service" />
                                </Form.Group>
                            </div>
                        </div>
                        <Button variant="success" type='submit'>
                            Save
                        </Button>
                    </Form>

                </Modal.Body>
            </Modal>
            {/* End */}

            {/* Delete section */}
            <Modal show={showDelete} onHide={handleCloseDel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete this service?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDelete} variant="danger" type='button'>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* End */}
        </div>
    )
}

export default Services
