import React from 'react'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed='top'>
                <Container>
                    <Navbar.Brand><Link style={{ textDecoration: 'none' }} className='text-reset' to="/">LBS Training & Development</Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {/* <Nav.Link href="#features">Features</Nav.Link>
                            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
                            <NavDropdown title="Setup" id="collasible-nav-dropdown">
                                <NavDropdown.Item><Link style={{ textDecoration: 'none' }} className='text-reset' to="services">Services</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link style={{ textDecoration: 'none' }} className='text-reset' to="trainingcenters">Training Centers</Link></NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Nav.Link><Link style={{ textDecoration: 'none' }} className='text-reset' to="login">Login</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header
