import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Table, Button, Modal, Form } from 'react-bootstrap'

const TrainingCenter = () => {

    const [trainingCenters, setTrainingCenters] = useState([])
    const [regions, setRegions] = useState([])

    //Add and edit modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [Name, setName] = useState('');
    const [RegionId, setRegionId] = useState();
    const [SelectedRegion, setSelectedRegion] = useState();
    //

    //Delete modal
    const [showDelete, setShowDel] = useState(false);

    const [Id, setTrainingCenterId] = useState(0);

    const handleCloseDel = () => setShowDel(false);
    //

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = {
                Id: Id,
                Name: Name,
                RegionId: RegionId,
            };

            axios.post("https://api.legendsqueue.com/api/training/trainingcenters/addorupdate", data)
                .then(res => {
                    handleClose();
                    if (Id !== 0) {
                        trainingCenters.forEach(trainingCenter => {
                            if (trainingCenter.Id === Id) {
                                trainingCenter.Name = trainingCenter.Name;
                                trainingCenter.RegionId = data.RegionId;
                                trainingCenter.Region = res.data.Region;
                            }
                        })
                    }
                    else {
                        data.Id = res.data.Id;
                        data.Region = res.data.Region;
                        setTrainingCenters([...trainingCenters, data]);
                    }

                    resetForm();

                }).catch(error => {

                })
        } catch (err) {

        }
    };

    function resetForm() {
        setName('')
        setRegionId(0);
        setTrainingCenterId(0)
    }

    useEffect(() => {
        axios.get("https://api.legendsqueue.com/api/training/trainingcenters/getall")
            .then(res => {
                setTrainingCenters(res.data);
            }).catch(error => {

            })
    }, [])

    function getTrainingCenter(id) {
        try {
            axios.get(`https://api.legendsqueue.com/api/training/trainingcenters/get/${id}`)
                .then(res => {
                    setName(res.data.Name)
                    setRegionId(res.data.RegionId)
                    setTrainingCenterId(res.data.Id)
                }).catch(error => {

                })
        } catch (err) {

        }
    };

    function getRegions() {
        try {
            axios.get(`https://api.legendsqueue.com/api/training/regions/getall`)
                .then(res => {
                    setRegions(res.data)
                    setRegionId(res.data[0].Id);
                    console.log(regions);
                }).catch(error => {

                })
        } catch (err) {

        }
    };

    function showDeleteModal(id) {
        setTrainingCenterId(id)
        setShowDel(true)
    }

    function showAddOrEditModal(id) {
        resetForm();
        getRegions();

        if (id > 0) {
            getTrainingCenter(id)
        } else {
            const regionid = regions[0].Id;
            setRegionId(regionid);
        }
        setSelectedRegion(RegionId);
        setShow(true);
    }

    let handleDelete = async (e) => {
        e.preventDefault();
        try {
            axios.post(`https://api.legendsqueue.com/api/training/trainingcenters/delete/${Id}`)
                .then(res => {
                    handleCloseDel();
                    const updatedTrainingCenters = trainingCenters.filter((item) => item.Id != Id);

                    setTrainingCenters(updatedTrainingCenters);
                }).catch(error => {

                })
            setTrainingCenterId(0);
        } catch (err) {

        }
    };

    return (
        <div className='container mt-5 pt-5'>
            <h2 className='mb-3'>Services</h2>
            <Button className='mb-3' variant="dark" onClick={showAddOrEditModal}>
                Add training center
            </Button>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Region</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {trainingCenters.map((trainingCenter, i) => (
                        <tr key={i}>
                            <td>{trainingCenter.Id}</td>
                            <td>{trainingCenter.Name}</td>
                            <td>{trainingCenter.Region}</td>
                            <td>
                                <a style={{ cursor: 'pointer' }} onClick={() => showDeleteModal(trainingCenter.Id)}>Delete</a>&nbsp;&nbsp;<a style={{ cursor: 'pointer' }} onClick={() => showAddOrEditModal(trainingCenter.Id)}>Edit</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Add and edit section */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add training center</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Training center</Form.Label>
                                    <Form.Control required value={Name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter training center name" />
                                </Form.Group>
                            </div>
                            <div className='col-sm-12'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Region</Form.Label>
                                    <select className='form-control' value={SelectedRegion} onChange={(e) => setRegionId(e.target.value)}>
                                        {regions.map((region, i) => (
                                            <option key={i} value={region.Id}>{region.Name}</option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </div>
                        </div>
                        <Button variant="success" type='submit'>
                            Save
                        </Button>
                    </Form>

                </Modal.Body>
            </Modal>
            {/* End */}

            {/* Delete section */}
            <Modal show={showDelete} onHide={handleCloseDel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete training center</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete this training center?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDelete} variant="danger" type='button'>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* End */}
        </div>
    )
}

export default TrainingCenter
