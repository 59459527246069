import React from 'react';
const logo = 'https://www.legendsqueue.com/Images/logo2.png';

const Home = () => {
    return <div className='text-center mt-5 pt-5'>
        <img style={{ maxWidth: '100%', width: '300px' }} src={logo} />
        <h1 className='fw-light'>Training & Development</h1>
    </div>;
};

export default Home;
